import { Button, Flex, Text, useColorMode, color } from 'deepstash-ui';
import LayoutAdapter from 'src/components/layout/LayoutAdapter';
import NextLink from 'src/components/navigation/NextLink';
import useRouter from 'hooks/useRouter';
import React from 'react';
import { typography } from 'theme';
import { missingImage } from 'utils/constants';

const NotFoundPage = () => {
  const { colorMode } = useColorMode();

  const router = useRouter();

  const errorPageMetadata = {
    pageTitle: '404 Page not found',
    pageDescription: 'The requested page could not be found.',
    canonicalTag: 'https://deepstash.com/404',
    robotsTag: 'noindex',
  };

  return (
    <LayoutAdapter pageMetadata={errorPageMetadata}>
      <Flex
        flexDirection={{ base: 'column', lg: 'row' }}
        mt={{ base: '0', lg: '125px' }}
        align="center"
        justifyContent="center"
      >
        <Flex flexDirection="column" w="50%">
          <Text fontSize="60px" fontWeight="300" color={color[colorMode].text}>
            404
          </Text>
          <Text
            mb="10px"
            {...typography.articleTitle}
            color={color[colorMode].text}
          >
            {router.asPath.includes('/u/') ? 'User' : 'Page'} Not Found
          </Text>
          <Text mb="60px" color={color[colorMode].text}>
            The page you are looking for was moved, removed, renamed or may have
            never existed.
          </Text>
          <NextLink href="/">
            <Button
              w="150px"
              px="20px"
              backgroundColor={color[colorMode].primary.default}
              color={color[colorMode].surface}
              _hover={undefined}
              _active={{ backgroundColor: color[colorMode].textMuted }}
              _focus={{
                outline: 'none',
              }}
              borderRadius={20}
              outline="none"
              mr="20px"
              mb="20px"
              fontSize="16px"
            >
              Go Home
            </Button>
          </NextLink>
        </Flex>
        <img src={missingImage(colorMode)} width="300px" />
      </Flex>
    </LayoutAdapter>
  );
};

export default NotFoundPage;
